import Card from "../UI/Card";
import TextResult from './TextResult';
import classes from './Results.module.css';
const Results =()=>{
    return(
<div className={classes.card}>
    <TextResult/>
    <TextResult />
    <TextResult />
</div>
    );
};
export default Results;
/* this component, renders the TextResult as a sample, theoretically the data has
to be fetched from a database */