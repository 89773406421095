import classes from './PosItemForm.module.css';

const PosItemForm = (props)=>{
    return(
        <form className={classes.form}>
        <button>Show Results</button>
        </form>
    );
};

export default PosItemForm;