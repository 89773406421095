import classes from "./AppButton.module.css";

const AppButton = (props) => {
  return (
    <button className={classes.button} onClick={props.onClick}>
      App
    </button>
  );
};

export default AppButton;
/* the button is an extra component , if clicked, it would call the function onClick
which is passed to the button , in header component.*/
