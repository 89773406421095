import classes from './PosItem.module.css';
import PosItemForm from './PosItemForm';

const PositionItem = props =>{
   return <li className={classes.positionbox}>
    <div><h3>{props.name}</h3></div>
    <div> 
        <PosItemForm/>
    </div>
   </li>
};
export default PositionItem;