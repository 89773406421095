import "./App.css";
import { Fragment, useState } from "react";
import Header from "./components/Layout/Header";
import WalletAddress from "./components/Layout/WalletAddress";
import Position from "./components/Positions/Positopn";
import Results from "./components/Positions/Result";
function App() {

  const [appSelect, setAppSelect] = useState(false);  // the var appSelect turns true, if the app bitton is clicked, the initial value is set with useState and is false
  const [walletSelect, setWalletSelect] = useState(false);
  /*walletSelect turns true, if the evaluate button is clicked*/

  const AppClickHandler = () => {
    setAppSelect(true); /** if the app button is clicked, this function is 
    called, and with the function setAppSelect turns the value to true */
  };
  const Walletclickhandler = () => {
    setAppSelect(false);
    setWalletSelect(true);
  };
  return (
    <Fragment>
      <Header onAppclick={AppClickHandler} />

      <main>
        {appSelect && <WalletAddress onWalletClick={Walletclickhandler} />}
        {walletSelect && <Results />} 
        {walletSelect && <Position />}
      </main>
    </Fragment>
  );
}
// line 27, if walletSelect is true, the programm renders the results and then Position component
export default App;
/* <Fragment> is needed to wrap other components when we want to render multipile components.
 {appSelect && <WalletAddress onWalletClick={Walletclickhandler} />} if 
 appSelect is true, (means if it is  clicked, because the function AppclickHandler turns it to true 
  once clicked), then it shows the wallet address option, once it is clicked, the function
  WalletclickHandler turns app select to false and walletSelect to true so the lines
  {walletSelect && <Results />} 
        {walletSelect && <Position />} are shown because walletSelect is now true */
