import classes from "./WalletAddress.module.css";

const WalletAddress = (props) => {
  return (
    <div className={classes.wrap}>
      <form>
        <input type="text" id="username" placeholder="Wallet address"></input>
      </form>
      <button className={classes.btn} onClick={props.onWalletClick}>
        Evaluate
      </button>
    </div>
  );
};

export default WalletAddress;
