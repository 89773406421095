import { Fragment } from "react";

import classes from "./Header.module.css";
import logoImage from "../../assets/logo.png";
import AppButton from "../UI/AppButton";
/* you import any other asset or component that you want to use, before defining the
the function */
const Header = (props) => {
  return (
    /* JSX is rseturned in the component, to accsses the related css block, 
    you have to use className, and pass the classes.(name of the block)*/
    /* you can also pass in other component in the JSX code, you just need to import it
    like the button component in the code*/
    <header className={classes.header}>
      <div className={classes["header__logo"]}>
        <img className={classes.logoImage} src={logoImage} alt="logo"></img>
      </div>

      <div className={classes["header__menu"]}>
        <nav className={classes["header__nav collapse"]}>
          <ul className={classes["header__elenco"]}>
            <li className={classes["header__el"]}>
              <a href="#" className={classes["header__link"]}>
                Motivation
              </a>
            </li>
            <li className={classes["header__el"]}>
              <a href="#" className={classes["header__link"]}>
                Our Approach
              </a>
            </li>
            <li className={classes["header__el"]}>
              <a href="#" className={classes["header__link"]}>
                About Us
              </a>
            </li>
            <li className={classes["header__el"]}>
              <a href="#" className={classes["header__link"]}>
                Reviews
              </a>
            </li>
            <li className={classes["header__el"]}>
              <a href="#" className={classes["header__link"]}>
                Contact Us
              </a>
            </li>
          </ul>
          <div className={classes.appstyle}>
            <AppButton onClick={props.onAppclick} />
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Header;
