import classes from './TextResult.module.css';

const TextResult =()=>{
    return (
        <section className={classes.summary}>
          <h2>here goes the analysis result</h2>
          <p>
          this is the graph
          </p>
          <p>
         this is the text
          </p>
          <p> this is the chart</p>
        </section>
      );
};
export default TextResult;
// the component prints out some sample data