import classes from './PosInfo.module.css';
import Card from '../UI/Card';
import PositionItem from './PositionItem';
const DUMMY_INFO =[
{
id: 'm1',
name:'position 1',
opp :'this is a good one',
risk: '10%',
},
{
  id: 'm2',
  name:'position 2',
  opp:'this is a good one',
  risk: '10%',
  },
  {
    id: 'm3',
    name:'position 3',
    opp:'this is a good one',
    risk: '10%',
    },
];

const PositionINformation=()=>{
    const riskList = DUMMY_INFO.map(risk=> <PositionItem key={risk.id} name={risk.name}/>);
    /* the above line, takes date (ideally from a backend, but here the dummy_info is only a sample)
    and for each item, it turns it in the form of <PositionItem>   */
    return (<section className={classes.pos}>
         <Card>
        <ul>
          {riskList} 
        </ul>
        </Card>

    </section>);
};
export default PositionINformation;